import React, { useEffect, useState, useRef } from "react";

import { useParams, Link } from "react-router-dom";
import axios from "axios";

import printJS from "print-js";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Toast } from "primereact/toast";

import { currencyAmount } from "../../../helpers/TemplateHelper";
import { currencyRate } from "../../../helpers/TemplateHelper";

function OrderwiseReport() {
  const printStyles = `
    body {font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif; }
    label, div {font-size: 11px}
    strong {font-size: 10px}
    h4 {font-size: 13px}
    table, td, th {border: 0.5px groove ; font-size: 11px; padding: 4px} 
    table {width: 100%; border-collapse: collapse; margin-bottom: 10px}
    th {font-weight: 400; text-align: left, padding: 4px}
    .updated-by {width: 130px !important}
    .updated {width: 100px !important}
    .text-align-right {text-align: right}
    .text-align-left {text-align: left}
    .border-none td {border: none; font-size: 11px; padding: 1px}`;

  const { id } = useParams();
  const toast = useRef(null);
  const [order, setOrder] = useState(null);
  const orderItems = order?.orderItems || [];

  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");

  const currentDate = new Date();

  const [showCustomerDetails, setShowCustomerDetails] = useState(true);
  const [showOrderSummary, setShowOrderSummary] = useState(true);
  const [showPaymentSummary, setShowPaymentSummary] = useState(true);
  const [showOrderItems, setShowOrderItems] = useState(true);
  const [showAddOnDetails, setShowAddOnDetails] = useState(true);
  const [showEventDetails, setShowEventDetails] = useState(true);
  const [showPaymentDetails, setShowPaymentDetails] = useState(true);
  const [showContributionDetails, setShowContributionDetails] = useState(true);
  const [showCurrencyConversionSummery, setShowCurrencyConversionSummery] =
    useState(true);

  useEffect(() => {
    fetchOrderData();
  }, []);

  const fetchOrderData = () => {
    axios
      .get(`/api/orders/${id}`)
      .then((response) => {
        setOrder(response.data);
      })
      .catch((error) => {
        console.error("Error fetching order:", error);
        toast.current.show({
          severity: "error",
          summary: "Order Error",
          detail: "An error occurred while fetching order. Please try again.",
          life: 3000,
        });
      });
  };

  function decodeJwt(token) {
    const parts = token.split(".");
    if (parts.length !== 3) {
      throw new Error("Invalid JWT format");
    }

    const payload = JSON.parse(atob(parts[1]));
    return payload;
  }

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      const decodedPayload = decodeJwt(token);
      const firstName = decodedPayload.firstName;
      const lastName = decodedPayload.lastName;
      setUserFirstName(firstName);
      setUserLastName(lastName);
    }
  }, []);

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "2-digit" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString("en-US", {
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  };

  function formatTableDate(dateString) {
    const options = {
      year: "2-digit",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };

    return new Date(dateString).toLocaleDateString("en-US", options);
  }

  const formattedDate = `${formatDate(currentDate)} - ${formatTime(
    currentDate
  )}`;

  const calculateTotalAddOnCost = () => {
    if (order?.addOns) {
      return order.addOns.reduce((total, addOn) => total + addOn.amount, 0);
    }
    return 0;
  };

  const calculateDiscountTotal = () => {
    let discountTotal = 0;

    if (order?.addOns) {
      order?.addOns.forEach((addOn) => {
        if (addOn.type === "discounts") {
          discountTotal += addOn.amount;
        }
      });
    }

    return discountTotal;
  };

  const discountTotal = calculateDiscountTotal();
  const otherAddonsTotal = calculateTotalAddOnCost() - discountTotal;

  const calculateSubTotal = () => {
    let subTotal = 0;

    if (order) {
      subTotal =
        (order.orderDetails?.subTotal || 0) +
        (order.orderDetails?.transportationCost || 0);
    }

    return subTotal;
  };

  const calculateTotal = () => {
    let total = 0;

    if (order) {
      total =
        calculateSubTotal() +
        (order.orderDetails?.fixingCost || 0) +
        otherAddonsTotal;
    }

    return total;
  };

  const totalReceivedPayment = order?.payments?.reduce(
    (total, payment) => total + payment.amount,
    0
  );

  const remainingPayment =
    calculateTotal() - (discountTotal + totalReceivedPayment);

  const handlePolesWidth =
    order?.orderItems?.some((item) => item.poleRequired) || false;

  const renderPrintOptions = () => {
    return (
      <div
        id="options"
        className="flex flex-row flex-wrap justify-content-between gap-4"
      >
        <div>
          <Checkbox
            inputId="customerDetailsCheckbox"
            checked={showCustomerDetails}
            onChange={(e) => setShowCustomerDetails(e.checked)}
            label="Customer Details"
          />
          <span className="ml-2">Customer Details</span>
        </div>
        <div>
          <Checkbox
            inputId="orderSummaryCheckbox"
            checked={showOrderSummary}
            onChange={(e) => setShowOrderSummary(e.checked)}
            label="Order Summary"
          />
          <span className="ml-2">Order Summary</span>
        </div>
        <div>
          <Checkbox
            inputId="paymentSummaryCheckbox"
            checked={showPaymentSummary}
            onChange={(e) => setShowPaymentSummary(e.checked)}
            label="Payment Summary"
          />
          <span className="ml-2">Payment Summary</span>
        </div>
        <div>
          <Checkbox
            inputId="orderItemsCheckbox"
            checked={showOrderItems}
            onChange={(e) => setShowOrderItems(e.checked)}
            label="Order Items"
          />
          <span className="ml-2">Order Items</span>
        </div>
        <div>
          <Checkbox
            inputId="addOnDetailsCheckbox"
            checked={showAddOnDetails}
            onChange={(e) => setShowAddOnDetails(e.checked)}
            label="AddOn Details"
          />
          <span className="ml-2">AddOn Details</span>
        </div>
        <div>
          <Checkbox
            inputId="paymentDetailsCheckbox"
            checked={showPaymentDetails}
            onChange={(e) => setShowPaymentDetails(e.checked)}
            label="Payment Details"
          />
          <span className="ml-2">Payment Details</span>
        </div>
        <div>
          <Checkbox
            inputId="contributionDetailsCheckbox"
            checked={showContributionDetails}
            onChange={(e) => setShowContributionDetails(e.checked)}
            label="Contribution Details"
          />
          <span className="ml-2">Contribution Details</span>
        </div>
        <div>
          <Checkbox
            inputId="eventDetailsCheckbox"
            checked={showEventDetails}
            onChange={(e) => setShowEventDetails(e.checked)}
            label="Event Details"
          />
          <span className="ml-2">Timeline Details</span>
        </div>
        <div>
          <Checkbox
            inputId="CurrencyConversionSummeryCheckbox"
            checked={showCurrencyConversionSummery}
            onChange={(e) => setShowCurrencyConversionSummery(e.checked)}
            label="Currency Conversion Summery"
          />
          <span className="ml-2">Currency Conversion Summery</span>
        </div>
      </div>
    );
  };

  const handlePrint = () => {
    printJS({
      printable: "orderwise-report",
      type: "html",
      scanStyles: false,
      style: printStyles,
    });
  };

  //to do
  // const handleDownload =() => {
  //   dowloadJS({
  //     printable:
  //     type: "html",
  //     scanStyles: false,
  //     style: printStyles,
  //   });
  // };

  return (
    <div className="container">
      <Toast ref={toast} position="bottom-right" />
      <div className="flex justify-content-between">
        <div>
          <h4 className="text-primary mb-1">Invoice</h4>
          <label className="text-secondary">View Invoice and print</label>
        </div>
        <div className="flex flex-row gap-2 flex-wrap h-1rem">
          <Link to={`/pages/order/${id}`}>
            <Button
              icon="pi pi-arrow-left"
              className="p-button-warning"
            ></Button>
          </Link>
          <Button
            label="Refresh Data"
            severity="warning"
            onClick={() => fetchOrderData()}
          />
          <Button
            label="Print Report"
            icon="pi pi-print"
            onClick={handlePrint}
          />
        </div>
      </div>

      {order && order?.status !== 0 && order?.status !== 2 && (
        <div className="card mt-3" id="options-select">
          <h6>Select Print Options : </h6>
          <div className="flex flex-row gap-2 flex-wrap justify-content-between">
            {renderPrintOptions()}
          </div>
        </div>
      )}

      <div id="orderwise-report" style={{ fontSize: "11px !important" }}>
        <div>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "space-between",
                gap: "200px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "15px",
                }}
              >
                <h4>Invoice</h4>
                <strong>
                  {order?.id.toString().padStart(8, "0")} -{" "}
                  {order?.created ? formatDate(order.created) : ""}
                  {" - "}
                  {order?.created ? formatTime(order.created) : ""}
                </strong>
                <div>Created: {formattedDate}</div>
                <div>
                  Created By: {userFirstName} {""} {userLastName}{" "}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    paddingTop: "16px",
                  }}
                >
                  <img
                    src={require("../../../images/logo.png")}
                    alt="logo"
                    style={{ height: "50px" }}
                  />
                </div>
                <div
                  className="text-align-right text-right"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <label>RAGAMA - WALPOLA - WELIMADA - NUWARAELIYA</label>
                  <label>Email : geethcurtains@gmail.com</label>
                  <label>Phone : 0718021976 - 0771786299</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "1px",
          }}
        >
          <div
            style={{
              flexDirection: "column",
              gap: "1px",
              flexWrap: "wrap",
              width: "100%",
              marginTop: "1px",
              marginRight: "0.5rem",
            }}
          >
            <div
              id="CustomerDetails"
              className="customer-details"
              style={{ display: showCustomerDetails ? "block" : "none" }}
            >
              <table className="order-table">
                <thead>
                  <tr>
                    <th
                      colSpan="2"
                      className="customer-details-heading text-align-left text-left"
                    >
                      <strong>Customer Details</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Name :</td>
                    <td>
                      <strong>
                        {order?.customerDetails?.title}
                        {". "}
                        {order?.customerDetails?.firstName}{" "}
                        {order?.customerDetails?.lastName}
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Email :</td>
                    <td>{order?.customerDetails?.email}</td>
                  </tr>
                  <tr>
                    <td>Contact No :</td>
                    <td>{order?.customerDetails?.telephone}</td>
                  </tr>
                  <tr>
                    <td>Address :</td>
                    <td>
                      {order?.customerDetails?.addressLine1}{" "}
                      {order?.customerDetails?.addressLine2}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div
              id="PaymentSummary"
              className="payment-summary"
              style={{
                display: showPaymentSummary ? "block" : "none",
                marginTop: "20px",
              }}
            >
              <table className="order-table">
                <thead>
                  <tr>
                    <th
                      colSpan="2"
                      className="payment-summary-heading text-align-left text-left"
                    >
                      <strong>Payment Summary</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {showPaymentSummary && (
                    <React.Fragment>
                      <tr>
                        <td>Total Order Payment:</td>
                        <td className="text-align-right text-right">
                          {`${currencyAmount(calculateTotal())} LKR`}
                        </td>
                      </tr>
                      <tr>
                        <td>Adjustment / Discount:</td>
                        <td className="text-align-right text-right">
                          {`${currencyAmount(discountTotal)} LKR`}
                        </td>
                      </tr>
                      <tr>
                        <td>Total Received Payment:</td>
                        <td className="text-align-right text-right">
                          {`${currencyAmount(totalReceivedPayment)} LKR`}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Remaining Payment:</strong>
                        </td>
                        <td className="text-align-right text-right">
                          <strong>{`${currencyAmount(
                            remainingPayment
                          )} LKR`}</strong>
                        </td>
                      </tr>
                    </React.Fragment>
                  )}
                </tbody>
              </table>
            </div>

            <div
              id="CurrencyConversionSummary"
              className="CurrencyConversion-summary"
              style={{
                display: showCurrencyConversionSummery ? "block" : "none",
                marginTop: "20px",
              }}
            >
              <table className="order-table">
                <thead>
                  <tr>
                    <th
                      colSpan="2"
                      className="CurrencyConversion-summary-heading text-align-left text-left"
                    >
                      <strong>Currency Conversion Summary</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {showCurrencyConversionSummery && (
                    <React.Fragment>
                      <tr>
                        <td>Currency:</td>
                        <td className="text-align-right text-right" />
                        {order?.currencyConversion?.currency}
                      </tr>
                      <tr>
                        <td>Currency Rate:</td>
                        <td className="text-align-right text-right" />
                        {order?.currencyConversion?.currencyRate}
                      </tr>
                      <tr>
                        <td>Amount:</td>
                        <td className="text-align-right text-right" />
                        {order?.currencyConversion?.amount}
                      </tr>
                      <tr>
                        <td>Delivery Charge:</td>
                        <td className="text-align-right text-right" />
                        {order?.currencyConversion?.deliveryCharge}
                      </tr>
                      <tr>
                        <td>
                          <strong>Total:</strong>
                          {order?.currencyConversion?.total}
                        </td>
                      </tr>
                    </React.Fragment>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div
            id="OrderSummary"
            style={{
              display: showOrderSummary ? "block" : "none",
              width: "100%",
              marginTop: "1.2px",
            }}
          >
            <table className="order-table">
              <thead>
                <tr>
                  <th
                    colSpan="2"
                    className="order-summary-heading text-align-left text-left"
                  >
                    <strong>Order Summary</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Total Quantity :</td>
                  <td className="text-align-right text-right">
                    {order?.orderDetails?.totalQty}
                  </td>
                </tr>
                <tr>
                  <td>Total Pleats :</td>
                  <td className="text-align-right text-right">
                    {order?.orderDetails?.totalPleats}
                  </td>
                </tr>
                {handlePolesWidth && (
                  <tr>
                    <td>Total Poles Width :</td>
                    <td className="text-align-right text-right">
                      {order?.orderDetails?.totalWidth} F
                    </td>
                  </tr>
                )}
                <tr>
                  <td>Distance :</td>
                  <td className="text-align-right text-right">
                    {order?.orderDetails?.distance} km(s)
                  </td>
                </tr>
                <tr>
                  <td>Fixing Cost :</td>
                  <td className="text-align-right text-right">
                    {`${currencyAmount(order?.orderDetails?.fixingCost)} LKR`}
                  </td>
                </tr>
                <tr>
                  <td>Transportation Cost :</td>
                  <td className="text-align-right text-right">
                    {`${currencyAmount(
                      order?.orderDetails?.transportationCost
                    )} LKR`}
                  </td>
                </tr>

                <tr>
                  <td>Total AddOns Cost :</td>
                  <td className="text-align-right text-right">
                    {`${currencyAmount(otherAddonsTotal)} LKR`}
                  </td>
                </tr>
                <tr>
                  <td>Sub Total :</td>
                  <td className="text-align-right text-right">
                    {`${currencyAmount(calculateSubTotal())} LKR`}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Total :</strong>
                  </td>
                  <td className="text-align-right text-right">
                    <strong>{`${currencyAmount(calculateTotal())} LKR`}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div
          id="OrderItems"
          className="border-none"
          style={{
            display: showOrderItems ? "block" : "none",
            marginTop: "15px",
          }}
        >
          <table className="order-table">
            <thead>
              <tr>
                <th
                  colSpan="2"
                  className="order-items-heading text-align-left text-left"
                >
                  <strong>Order Items</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              {orderItems.map((item, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td className="item-description">
                      <span>
                        <strong>
                          {item?.selectedDesign.description} /{" "}
                          {item?.selectedDesign.code}
                        </strong>
                        <strong>
                          {item.type} /{" "}
                          {item.poleRequired ? "With Pole" : "Without Pole"}
                        </strong>
                      </span>
                    </td>
                    <td className="text-align-right flex flex-row justify-content-between">
                      <strong>Total :</strong>
                      <strong>{`${currencyAmount(item.total)} LKR`}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td className="item-dimensions">
                      <label>Dimensions :</label>
                      <label>
                        Width: {item.width} Inches / Height: {item.height}{" "}
                        Inches
                      </label>
                    </td>
                    <td className="text-align-right flex flex-row justify-content-between">
                      <label>No. of Pleats :</label>
                      <label>{item.pleats} Pleats</label>
                    </td>
                  </tr>
                  <tr>
                    <td className="item-quantity">
                      <label>Quantity :</label>
                      <label>{item.quantity} Set(s)</label>
                    </td>
                    <td className="text-align-right flex flex-row justify-content-between">
                      <label>Unit Price :</label>
                      <label>{`${currencyAmount(item.cost)} LKR`}</label>
                    </td>
                  </tr>
                  <tr
                    style={{
                      borderBottom: "1px solid #b7b7b7",
                      paddingTop: "2px",
                    }}
                  >
                    <td
                      style={{
                        borderBottom: "1px solid #b7b7b7",
                        paddingTop: "2px",
                      }}
                    >
                      {item.remark ? (
                        <span style={{ fontSize: "13px" }}>
                          Remark : {item.remark}
                        </span>
                      ) : null}
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>

        {order?.addOns.length > 0 && (
          <div
            id="AddOnDetails"
            style={{
              display: showAddOnDetails ? "block" : "none",
              marginTop: "20px",
            }}
          >
            <strong className="strong-margin-bottom">Addon Details</strong>
            <table className="order-table">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Remark</th>
                  <th style={{ width: "120px" }}>Amount</th>
                  <th className="updated" style={{ width: "140px" }}>
                    Updated
                  </th>
                  <th className="updated-by" style={{ width: "180px" }}>
                    Updated By
                  </th>
                </tr>
              </thead>
              <tbody>
                {order?.addOns.map((addon) => (
                  <tr key={addon.id}>
                    <td>{addon.type}</td>
                    <td>{addon.remark}</td>
                    <td className="text-align-right text-right">{`${currencyAmount(
                      addon.amount
                    )} LKR`}</td>
                    <td>{formatTableDate(addon.updated)}</td>
                    <td>{addon.updatedByName}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {order?.payments.length > 0 && (
          <div
            id="PaymentDetails"
            style={{
              display: showPaymentDetails ? "block" : "none",
              marginTop: "1rem",
            }}
          >
            <strong style={{ marginBottom: "5px" }}>Payment Details</strong>
            <table className="order-table">
              <thead>
                <tr>
                  <th style={{ width: "100px" }}>Bill No</th>
                  <th>Remark</th>
                  <th style={{ width: "120px" }}>Amount</th>
                  <th className="updated" style={{ width: "140px" }}>
                    Updated
                  </th>
                  <th className="updated-by" style={{ width: "180px" }}>
                    Updated By
                  </th>
                </tr>
              </thead>
              <tbody>
                {order?.payments.map((payment) => (
                  <tr key={payment.id}>
                    <td>{payment.referenceNo}</td>
                    <td>{payment.remark}</td>
                    <td className="text-align-right text-right">{`${currencyAmount(
                      payment.amount
                    )} LKR`}</td>
                    <td>{formatTableDate(payment.updated)}</td>
                    <td>{payment.updatedByName}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {order?.contributions.length > 0 && (
          <div
            id="ContributionDetails"
            style={{
              display: showContributionDetails ? "block" : "none",
              marginTop: "1rem",
            }}
          >
            <strong style={{ marginBottom: "5px" }}>
              Contribution Details
            </strong>
            <table className="order-table">
              <thead>
                <tr>
                  <th className="updated-by" style={{ width: "180px" }}>
                    Employee Name
                  </th>
                  <th>Type</th>
                  <th>Value</th>
                  <th style={{ width: "120px" }}>Amount</th>
                  <th className="updated" style={{ width: "140px" }}>
                    Updated
                  </th>
                  <th className="updated-by" style={{ width: "180px" }}>
                    Updated By
                  </th>
                </tr>
              </thead>
              <tbody>
                {order?.contributions.map((contribution) => (
                  <tr key={contribution.id}>
                    <td>{contribution.userName}</td>
                    <td>{contribution.type}</td>
                    <td>{`${contribution.value} ${contribution.measureUnit}(s)`}</td>
                    <td className="text-align-right text-right">{`${currencyAmount(
                      contribution.cost
                    )} LKR`}</td>
                    <td>{formatTableDate(contribution.updated)}</td>
                    <td>{contribution.updatedByName}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {order?.events.length > 0 && (
          <div
            id="EventDetails"
            style={{
              display: showEventDetails ? "block" : "none",
              marginTop: "1rem",
            }}
          >
            <strong style={{ marginBottom: "5px" }}>Timeline Details</strong>
            <table className="order-table">
              <thead>
                <tr>
                  <th>Event</th>
                  <th>Remark</th>
                  <th className="updated" style={{ width: "140px" }}>
                    Updated
                  </th>
                  <th className="updated-by" style={{ width: "180px" }}>
                    Updated By
                  </th>
                </tr>
              </thead>
              <tbody>
                {order?.events.map((event) => (
                  <tr key={event.id}>
                    <td>{event.eventName}</td>
                    <td>{event.remark}</td>
                    <td>{formatTableDate(event.updated)}</td>
                    <td>{event.updatedByName}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}

export default OrderwiseReport;
