import React, { useState, useEffect, useRef } from "react";

import axios from "axios";

import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";

import { Toast } from "primereact/toast";

import { currencyAmount } from "../../helpers/TemplateHelper";

const initialform = {
  itemId: null,
  unitPrice: null,
  sellingPrice: null,
  quantity: null,
};

const GoodsReceiveDialog = ({ onHide, onSubmit }) => {
  const [form, setform] = useState(initialform);
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [itemOptions, setItemOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const toast = useRef(null);

  useEffect(() => {
    fetchItems();
  }, []);

  useEffect(() => {
    axios
      .get("/api/keyValuePairs/getByKey/suppliers")
      .then((response) => {
        const supplierOptions = response.data.map((supplier) => ({
          label: supplier.value,
          value: supplier.value,
        }));
        setSupplierOptions(supplierOptions);
      })
      .catch(() => {
        toast.current.show({
          severity: "error",
          summary: "Supplier Error",
          detail:
            "An error occurred while fetching suppliers. Please try again.",
          life: 3000,
        });
      });
  }, []);

  const fetchItems = async () => {
    try {
      const response = await axios.get("/api/items");
      const options = response.data.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setItemOptions(options);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Loading Error",
        detail: "Failed to load items.",
        life: 3000,
      });
    }
  };

  const handleChange = (name, value) => {
    setform({ ...form, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!form.itemId) {
      newErrors.itemId = "Item selection is required.";
    }
    if (form.unitPrice <= 0 || form.unitPrice === null) {
      newErrors.unitPrice = "Purchase price must be greater than 0.";
    }
    if (form.sellingPrice <= 0 || form.sellingPrice === null) {
      newErrors.sellingPrice = "Selling price must be greater than 0.";
    }
    if (form.quantity <= 0 || form.quantity === null) {
      newErrors.quantity = "Quantity must be greater than 0.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        await axios.post("/api/goodReceives", form);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Goods received successfully.",
          life: 3000,
        });
        onSubmit();
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to receive goods.",
          life: 3000,
        });
      }
    }
  };

  return (
    <div>
      <Toast ref={toast} position="bottom-right" />
      <form onSubmit={handleSubmit}>
        <div className="p-field">
          <label htmlFor="item">Item *</label>
          <Dropdown
            id="item"
            value={form.itemId}
            options={itemOptions}
            onChange={(e) => handleChange("itemId", e.value)}
            placeholder="Select Item"
          />
          {errors.itemId && <small className="p-error">{errors.itemId}</small>}
        </div>
        <div className="p-field">
          <label htmlFor="supplier">Supplier *</label>
          <Dropdown
            id="supplier"
            name="supplier"
            options={supplierOptions}
            value={form.supplier || ""}
            onChange={handleChange}
            placeholder="Select a Supplier"
          />
          {errors.supplier && (
            <label className="p-error">{errors.supplier}</label>
          )}
        </div>
        <div className="p-field">
          <label htmlFor="unitPrice">Purchase Price *</label>
          <InputNumber
            id="unitPrice"
            value={form.unitPrice}
            onValueChange={(e) => handleChange("unitPrice", e.value)}
            mode="currency"
            currency="LKR"
          />
        </div>
        <div className="p-field">
          <label htmlFor="sellingPrice">Selling Price *</label>
          <InputNumber
            id="sellingPrice"
            value={form.sellingPrice}
            onValueChange={(e) => handleChange("sellingPrice", e.value)}
            mode="currency"
            currency="LKR"
          />
        </div>
        <div className="p-field">
          <label htmlFor="quantity">Quantity *</label>
          <InputNumber
            id="quantity"
            value={form.quantity}
            onValueChange={(e) => handleChange("quantity", e.value)}
          />
        </div>
        <div className="p-field">
          <div className="flex justify-content-between font-bold">
            <div>
              Total Unit Price: {currencyAmount(form.unitPrice * form.quantity)}
            </div>
            <div>
              Total Selling Price:{" "}
              {currencyAmount(form.sellingPrice * form.quantity)}
            </div>
          </div>
        </div>
        <div className="p-field">
          <label htmlFor="remark">Remark</label>
          <InputTextarea
            id="remark"
            name="remark"
            rows={4}
            value={form.remark || ""}
            onChange={handleChange}
            autoComplete="off"
          />
        </div>
        <div className="flex justify-content-end">
          <Button
            type="button"
            label="Cancel"
            className="p-button-text mr-2"
            onClick={onHide}
          />
          <Button type="submit" label="Submit" />
        </div>
      </form>
    </div>
  );
};

export default GoodsReceiveDialog;
